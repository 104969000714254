const mockEnpoints = [
  'projects',
  'dashboard',
  'dashboard/graphs/a7456af9-6e2c-42be-8585-c1c59c76e123',
  'dashboard/graphs/d5ab3b85-6ceb-6060-b309-0f7a8f4cf4e4',
  'dashboard/graphs/a7416af9-6e2c-42be-9182-c1c59c76e475',
  'dashboard/widgets/words-total',
  'dashboard/widgets/invoices-average',
  'dashboard/widgets/spend-total',
  'dashboard/widgets/satisfaction-score',
  'dashboard/widgets/projects-open',
  'resources/offices',
  'dashboard/widgets/invoices-unpaid',
  'dashboard/widgets/quotes-open',
  'quotes',
  'projects/168855',
  'projects/778562',
  'projects/177751',
  'projects/178125',
  'projects/167114',
  'projects/123456',
  'projects/168890',
  'projects/167981',
  'projects/168855/instructions',
  'projects/778562/instructions',
  'projects/177751/instructions',
  'projects/178125/instructions',
  'projects/167114/instructions',
  'projects/123456/instructions',
  'projects/168890/instructions',
  'projects/167981/instructions',
  'quotes/57896',
  'quotes/54325',
  'quotes/96325',
  'quotes/74851',
  'quotes/57962',
  'quotes/123456',
  'quotes/54325/approve',
  'quotes/96325/approve',
  'quotes/54325/reject',
  'quotes/96325/reject',
  'dashboard/gallery',
  'dashboard/graphs/ae219ddf-0000-4471-95ed-94fdfaf52233',
  'dashboard/graphs/a3336af9-6e2c-42be-1111-c1c59c76e000',
  'dashboard/graphs/5bd877b9-2d3c-4ebe-1111-0ecf9f524545',
  'templates',
  'invoices',
  'resources/specializations',
  'resources/settings/1459',
  'resources/services/1459',
  'resources/priceprofiles/1459',
  'resources/languages/1459',
  'resources/deadlines/1459',
  'resources/services?all=true',
  'resources/languages?all=true',
  'resources/languages?all=false',
  'resources/services?all=false',
  'resources/reject-reasons',
  'files',
  'projects/v2',
  'templates/92',
  'resources/settings/schema',
  'resources/settings',
  'members',
  'quotes/55252',
  'quotes/25259',
  'templates/123456',
]

export default [
  ...mockEnpoints,
]
