const dashboardData = [
  {
    id: 'a7456af9-6e2c-42be-8585-c1c59c76e123',
    slug: 'projects-comparison-year',
    name: 'Project comparison year',
    type: '7',
    description: 'return Project comparison year',
    category: 'projects',
    options: {
      h: 6,
      w: 1,
      x: 0,
      y: 0,
    },
  },
  {
    id: 'd5ab3b85-6ceb-6060-b309-0f7a8f4cf4e4',
    slug: 'spend-historical',
    name: 'Spend historical',
    type: '9',
    description: 'return spend historicals',
    category: 'finance',
    options: {
      h: 6,
      w: 1,
      x: 1,
      y: 0,
    },
  },
  {
    id: 'a7416af9-6e2c-42be-9182-c1c59c76e475',
    slug: 'tasks-rush-language',
    name: 'Tasks rush language',
    type: '8',
    description: 'return Tasks rush language',
    category: 'finance',
    options: {
      h: 6,
      w: 1,
      x: 1,
      y: 6,
    },
  },
]

const columnMonthData = {
  categories: [
    'Nov 2023',
    'Dec 2023',
    'Jan 2024',
    'Feb 2024',
    'Mar 2024',
    'Apr 2024',
    'May 2024',
    'Jun 2024',
    'Jul 2024',
    'Aug 2024',
    'Sep 2024',
  ],
  series: [
    {
      name: 'Current Period',
      data: [
        0,
        0,
        0,
        0,
        0,
        26059.01,
        0,
        0,
        0,
        324.6,
        0,
      ],
    },
  ],
}

const columnQuarterData = {
  categories: [
    '2021 Q4-2022 Q4',
    '2022 Q1-2023 Q1',
    '2022 Q2-2023 Q2',
    '2022 Q3-2023 Q3',
    '2022 Q4-2023 Q4',
    '2023 Q1-2024 Q1',
    '2023 Q2-2024 Q2',
  ],
  series: [
    {
      name: 'Current Period',
      data: [
        0,
        0,
        0,
        0,
        0,
        0,
        26059.01,
      ],
    },
  ],
}

const columnYearData = {
  categories: [
    '2021',
    '2022',
    '2023',
    '2024',
  ],
  series: [
    {
      name: 'OPENED',
      data: [
        null,
        null,
        41,
        144,
      ],
    },
    {
      name: 'CLOSED',
      data: [
        null,
        null,
        1,
        null,
      ],
    },
  ],
}

const linearMonthData = {
  categories: [
    'Nov 2023',
    'Dec 2023',
    'Jan 2024',
    'Feb 2024',
    'Mar 2024',
    'Apr 2024',
    'May 2024',
    'Jun 2024',
    'Jul 2024',
    'Aug 2024',
    'Sep 2024',
    'Oct 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Nov 2023', y: 0 },
        { name: 'Dec 2023', y: 0 },
        { name: 'Jan 2024', y: 0 },
        { name: 'Feb 2024', y: 0 },
        { name: 'Mar 2024', y: 0 },
        { name: 'Apr 2024', y: 26059.01 },
        { name: 'May 2024', y: 0 },
        { name: 'Jun 2024', y: 0 },
        { name: 'Jul 2024', y: 0 },
        { name: 'Aug 2024', y: 324.6 },
        { name: 'Sep 2024', y: 0 },
        { name: 'Oct 2024', y: 0 },
      ],
    },
  ],
}

const linearQuarterData = {
  categories: [
    'Q4 2022',
    'Q1 2023',
    'Q2 2023',
    'Q3 2023',
    'Q4 2023',
    'Q1 2024',
    'Q2 2024',
    'Q3 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Q4 2022', y: 0 },
        { name: 'Q1 2023', y: 0 },
        { name: 'Q2 2023', y: 0 },
        { name: 'Q3 2023', y: 0 },
        { name: 'Q4 2023', y: 0 },
        { name: 'Q1 2024', y: 0 },
        { name: 'Q2 2024', y: 26059.01 },
        { name: 'Q3 2024', y: 324.6 },
      ],
    },
  ],
}

const linearYearData = {
  categories: [
    '2021',
    '2022',
    '2023',
    '2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 2021, y: 0 },
        { name: 2022, y: 0 },
        { name: 2023, y: 0 },
        { name: 2024, y: 26383.61 },
      ],
    },
  ],
}

const columnLast7DaysMonthData = {
  categories: [
    '2023 Oct-2024 Oct',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        1,
      ],
    },
    {
      name: 'Current Period',
      data: [
        2,
      ],
    },
  ],
}

const columnLast30DaysMonthData = {
  categories: [
    '2023 Sep-2024 Sep',
    '2023 Oct-2024 Oct',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        0,
        2,
      ],
    },
    {
      name: 'Current Period',
      data: [
        0,
        2,
      ],
    },
  ],
}

const columnLast90DaysMonthData = {
  categories: [
    '2023 Jul-2024 Jul',
    '2023 Aug-2024 Aug',
    '2023 Sep-2024 Sep',
    '2023 Oct-2024 Oct',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        1,
        1,
        0,
        2,
      ],
    },
    {
      name: 'Current Period',
      data: [
        12,
        22,
        0,
        2,
      ],
    },
  ],
}

const linearLast7DaysMonthData = {
  categories: [
    'Oct 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Oct 2024', y: 2 },
      ],
    },
  ],
}

const linearLast30DaysMonthData = {
  categories: [
    'Sep 2024',
    'Oct 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Sep 2024', y: 0 },
        { name: 'Oct 2024', y: 2 },
      ],
    },
  ],
}

const linearLast90DaysMonthData = {
  categories: [
    'Jul 2024',
    'Aug 2024',
    'Sep 2024',
    'Oct 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Jul 2024', y: 1 },
        { name: 'Aug 2024', y: 1 },
        { name: 'Sep 2024', y: 0 },
        { name: 'Oct 2024', y: 2 },
      ],
    },
  ],
}

const columnLast7DaysQuarterData = {
  categories: [
    '2023 Q3-2024 Q3',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        1,
      ],
    },
    {
      name: 'Current Period',
      data: [
        2,
      ],
    },
  ],
}

const columnLast30DaysQuarterData = {
  categories: [
    '2023 Q2-2024 Q2',
    '2023 Q3-2024 Q3',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        0,
        2,
      ],
    },
    {
      name: 'Current Period',
      data: [
        0,
        2,
      ],
    },
  ],
}

const columnLast90DaysQuarterData = {
  categories: [
    '2023 Q1-2024 Q1',
    '2023 Q2-2024 Q2',
    '2023 Q3-2024 Q3',
    '2023 Q4-2024 Q4',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        1,
        1,
        0,
        2,
      ],
    },
    {
      name: 'Current Period',
      data: [
        12,
        22,
        0,
        2,
      ],
    },
  ],
}

const linearLast7DaysQuarterData = {
  categories: [
    'Q3 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Q3 2024', y: 2 },
      ],
    },
  ],
}

const linearLast30DaysQuarterData = {
  categories: [
    'Q2 2024',
    'Q3 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Q2 2024', y: 0 },
        { name: 'Q3 2024', y: 2 },
      ],
    },
  ],
}

const linearLast90DaysQuarterData = {
  categories: [
    'Q1 2024',
    'Q2 2024',
    'Q3 2024',
    'Q4 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Q1 2024', y: 1 },
        { name: 'Q2 2024', y: 1 },
        { name: 'Q3 2024', y: 0 },
        { name: 'Q4 2024', y: 2 },
      ],
    },
  ],
}

const columnLast7DaysYearData = {
  categories: [
    '2023',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        1,
      ],
    },
    {
      name: 'Current Period',
      data: [
        2,
      ],
    },
  ],
}

const columnLast30DaysYearData = {
  categories: [
    '2022',
    '2023',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        0,
        2,
      ],
    },
    {
      name: 'Current Period',
      data: [
        0,
        2,
      ],
    },
  ],
}

const columnLast90DaysYearData = {
  categories: [
    '2021',
    '2022',
    '2023',
    '2024',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        1,
        1,
        0,
        2,
      ],
    },
    {
      name: 'Current Period',
      data: [
        12,
        22,
        0,
        2,
      ],
    },
  ],
}

const linearLast7DaysYearData = {
  categories: [
    '2023',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: '2023', y: 2 },
      ],
    },
  ],
}

const linearLast30DaysYearData = {
  categories: [
    '2022',
    '2023',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: '2022', y: 0 },
        { name: '2023', y: 2 },
      ],
    },
  ],
}

const linearLast90DaysYearData = {
  categories: [
    '2021',
    '2022',
    '2023',
    '2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: '2021', y: 1 },
        { name: '2022', y: 1 },
        { name: '2023', y: 0 },
        { name: '2024', y: 2 },
      ],
    },
  ],
}

const quotes = [
  {
    id: '57896',
    idNumber: 'Q-MTC-20241234-6',
    refNumber: '9870',
    name: 'TestUser',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '391',
        symbol: 'BEM',
        name: 'Bemba',
      },
    ],
    targetLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    inputFiles: [
      {
        id: '4029758',
        name: 'sql.drawio.png',
      },
    ],
    customFields: {
      cost_center: 'UK',
      nuid: '123456',
      billing_contact: '7583931234',
      otn_number: '123',
      domain: 'Elections',
      function: 'Avant page',
      genre: 'EOC',
      purpose: 'testing',
      source: 'AvantPortal',
      invoice_address: 'Customer',
      invoice_notes: true,
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-07-17T08:04:45.139000Z',
    deadline: '2024-07-17T11:30:00.000000Z',
    status: 'requested',
    customerSpecialInstructions: 'Smoke testing',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: 'Smoke testing',
    office: 'Beta Client AvantPortal',
    specialization: 'General',
  },
  {
    id: '54325',
    idNumber: 'Q-MTC-20245432-1',
    refNumber: null,
    name: 'July 12th ',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    targetLanguages: [
      {
        id: '83',
        symbol: 'FR-FR',
        name: 'French (France)',
      },
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
      {
        id: '74',
        symbol: 'ET',
        name: 'Estonian',
      },
    ],
    inputFiles: [
      {
        id: '4029708',
        name: 'download (17).csv',
      },
    ],
    customFields: {
      domain: 'Healthcare',
      genre: 'EOC',
      source: 'AvantPortal',
      invoice_address: 'Contact Person',
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-07-12T18:44:40.778000Z',
    deadline: '2024-07-19T23:00:00.000000Z',
    status: 'SENT',
    customerSpecialInstructions: null,
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Transcription',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: null,
    office: 'Beta Client AvantPortal',
    specialization: 'Government',
  },
  {
    id: '96325',
    idNumber: 'Q-MTC-20247413-1',
    refNumber: '12345',
    name: 'test project 963',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    targetLanguages: [
      {
        id: '48',
        symbol: 'ZH-TW',
        name: 'Chinese (Taiwan/Traditional)',
      },
      {
        id: '83',
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    inputFiles: [
      {
        id: '4029530',
        name: 'download.csv',
      },
    ],
    customFields: {
      cost_center: '12345678910',
      audience: 'Test',
      domain: 'Healthcare',
      function: 'Test',
      genre: 'EOC',
      purpose: 'Test',
      source: 'AvantPortal',
      invoice_address: 'Contact Person',
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-07-09T11:44:01.508000Z',
    deadline: '2024-07-12T15:00:00.000000Z',
    status: 'SENT',
    customerSpecialInstructions: 'blabgldksnsnkskndwk',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '58123',
      firstName: 'Avantpage',
      lastName: 'Demo',
      email: 'demo@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: 'blabgldksnsnkskndwk',
    office: 'Beta Client AvantPortal',
    specialization: 'General',
  },
  {
    id: '74851',
    idNumber: 'Q-MTC-20248896-5',
    refNumber: 'daads',
    name: 'demo project 748',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '48',
        symbol: 'ZH-TW',
        name: 'Chinese (Taiwan/Traditional)',
      },
    ],
    targetLanguages: [
      {
        id: '391',
        symbol: 'BEM',
        name: 'Bemba',
      },
    ],
    inputFiles: [
      {
        id: '4029400',
        name: 'arsha_digital_sign.png',
      },
    ],
    customFields: {
      domain: 'Healthcare',
      genre: 'EOC',
      source: 'AvantPortal',
      invoice_address: 'Contact Person',
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-06-20T07:38:30.346000Z',
    deadline: '2024-06-27T11:30:00.000000Z',
    status: 'requested',
    customerSpecialInstructions: 'test special instructions',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Transcription',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '58123',
      firstName: 'Avantpage',
      lastName: 'Demo',
      email: 'demo@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: 'test instructions',
    office: 'Beta Client AvantPortal',
    specialization: 'Government',
  },
  {
    id: '57962',
    idNumber: 'Q-MTC-20247799-4',
    refNumber: 'daads',
    name: 'demo project 579',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    targetLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    inputFiles: [],
    customFields: {
      domain: 'Healthcare',
      genre: 'EOC',
      source: 'AvantPortal',
      invoice_address: 'Contact Person',
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-06-20T07:36:47.180000Z',
    deadline: '2024-06-27T11:30:00.000000Z',
    status: 'requested',
    customerSpecialInstructions: 'customer instructions 01',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '58123',
      firstName: 'Avantpage',
      lastName: 'Demo',
      email: 'demo@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: 'instructions 01',
    office: 'Beta Client AvantPortal',
    specialization: 'Government',
  },
  {
    id: '55252',
    idNumber: 'Q-MTC-20246644-6',
    refNumber: 'daads',
    name: 'demo project 552',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    targetLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    inputFiles: [],
    customFields: {
      domain: 'Healthcare',
      genre: 'EOC',
      source: 'AvantPortal',
      invoice_address: 'Contact Person',
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-06-20T07:36:47.180000Z',
    deadline: '2024-06-27T11:30:00.000000Z',
    status: 'ACCEPTED',
    customerSpecialInstructions: 'customer instructions 02',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '58123',
      firstName: 'Avantpage',
      lastName: 'Demo',
      email: 'demo@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: 'instructions 02',
    office: 'Beta Client AvantPortal',
    specialization: 'Government',
  },
  {
    id: '25259',
    idNumber: 'Q-MTC-20241122-7',
    refNumber: 'ABC123',
    name: 'demo project 252',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    targetLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    inputFiles: [],
    customFields: {
      domain: 'Healthcare',
      genre: 'EOC',
      source: 'AvantPortal',
      invoice_address: 'Contact Person',
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-06-20T07:36:47.180000Z',
    deadline: '2024-06-27T11:30:00.000000Z',
    status: 'REJECTED',
    customerSpecialInstructions: 'customer instructions 03',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '58123',
      firstName: 'Avantpage',
      lastName: 'Demo',
      email: 'demo@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: 'instructions 03',
    office: 'Beta Client AvantPortal',
    specialization: 'Government',
  },
]

const members = {
  members: [
    {
      id: '22566',
      active: true,
      contact: {
        name: 'Test_permission',
        lastName: 'Test',
        personPosition: null,
        email: 'test444@yopmail.com',
        phone: '+1 123 456 789',
        addressPhone2: '',
        addressPhone3: '',
        mobilePhone: '',
        fax: '',
      },
      personDepartment: 'Administration',
      picture: null,
      twoFactorEnabled: null,
      roles: [
        'ROLE_CP_BASE_ACCOUNT',
        'ROLE_CP_QUOTES_EDITOR',
        'ROLE_CP_PROJECTS_EDITOR',
      ],
      abilities: [],
      office: null,
      preferences: null,
      username: 'test444@yopmail.com',
      scope: 'ONLY_RELATED_QUOTES_AND_PROJECTS',
      status: 'ready',
      lastLogin: null,
      lastFailedLogin: null,
      onboarding: {
        ready: true,
        status: 'ready',
        requirements: [],
        warnings: [],
      },
      passwordUpdatedAt: null,
    },
    {
      id: '58965',
      active: true,
      contact: {
        name: 'Other',
        lastName: 'Contact',
        personPosition: null,
        email: 'john@avantpage.com',
        phone: '',
        addressPhone2: '',
        addressPhone3: '',
        mobilePhone: '',
        fax: '',
      },
      personDepartment: null,
      picture: null,
      twoFactorEnabled: null,
      roles: [
        'ROLE_CP_ADMIN',
      ],
      abilities: [],
      office: null,
      preferences: null,
      username: 'john4',
      scope: 'OFFICE',
      status: 'pending',
      lastLogin: '2023-12-08T08:59:54.000000Z',
      lastFailedLogin: null,
      onboarding: {
        ready: false,
        status: 'pending',
        requirements: [
          'usernameMismatch',
          'usernameInvalid',
        ],
        warnings: [],
      },
      passwordUpdatedAt: null,
    },
    {
      id: '86957',
      active: true,
      contact: {
        name: 'Test',
        lastName: 'Notifications',
        personPosition: null,
        email: 'test@avantpage.com',
        phone: '+1 123 456 789',
        addressPhone2: '+919633010969',
        addressPhone3: '+16478294509',
        mobilePhone: '',
        fax: '',
      },
      personDepartment: null,
      picture: null,
      twoFactorEnabled: null,
      roles: [
        'ROLE_CP_DASHBOARD_READER',
        'ROLE_CP_PROJECTS_READER',
        'ROLE_CP_QUOTES_READER',
        'ROLE_CP_INVOICES_READER',
        'ROLE_AP_REPORTS_READER',
        'ROLE_CP_BASE_ACCOUNT',
      ],
      abilities: [],
      office: null,
      preferences: null,
      username: 'testusername',
      scope: 'OFFICE',
      status: 'active',
      lastLogin: '2024-11-19T19:54:24.000000Z',
      lastFailedLogin: null,
      onboarding: {
        ready: true,
        status: 'active',
        requirements: [],
        warnings: [],
      },
      passwordUpdatedAt: null,
    },
  ],
}

const projects = [
  {
    id: '168855',
    idNumber: 'MTC-20242277-1',
    refNumber: null,
    name: 'demo',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '83',
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    targetLanguages: [
      {
        id: '391',
        symbol: 'BEM',
        name: 'Bemba',
      },
    ],
    inputFiles: [],
    additionalContacts: [],
    startDate: '2024-10-15T04:38:33.055000Z',
    deadline: '2024-10-30T20:00:00.000000Z',
    deliveryDate: null,
    closeDate: null,
    status: 'opened',
    customerSpecialInstructions: '',
    costCenter: null,
    currency: {
      symbol: '$',
      name: 'USD',
    },
    confirmationSentDate: null,
    service: 'Translation + DTP',
    specialization: 'General',
    rapidFire: null,
    rush: null,
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    feedbacks: [],
    office: 'Beta Client AvantPortal',
    progress: {
      total: 0,
      percentage: '0.00',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    surveySent: null,
    archived: null,
    quoteId: null,
    invoiceId: null,
    invoiceNumber: null,
    tasksForReview: [],
    customFields: null,
    customer: {
      id: '8585',
      name: 'Beta Client AvantPortal',
    },
  },
  {
    id: '778562',
    idNumber: 'MTC-20299662-1',
    refNumber: null,
    name: 'demo project 01',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '83',
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    targetLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    inputFiles: [],
    additionalContacts: [],
    startDate: '2024-10-12T16:32:13.096000Z',
    deadline: '2024-10-30T20:00:00.000000Z',
    deliveryDate: null,
    closeDate: null,
    status: 'opened',
    customerSpecialInstructions: '',
    costCenter: null,
    currency: {
      symbol: '$',
      name: 'USD',
    },
    confirmationSentDate: null,
    service: 'Translation + DTP',
    specialization: 'General',
    rapidFire: null,
    rush: null,
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    feedbacks: [],
    office: 'Beta Client AvantPortal',
    progress: {
      total: 0,
      percentage: '0.00',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    surveySent: null,
    archived: null,
    quoteId: null,
    invoiceId: null,
    invoiceNumber: null,
    tasksForReview: [],
    customFields: null,
    customer: {
      id: '8585',
      name: 'Beta Client AvantPortal',
    },
  },
  {
    id: '177751',
    idNumber: 'MTC-27451009-1',
    refNumber: null,
    name: 'demo project 177',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    targetLanguages: [
      {
        id: '391',
        symbol: 'BEM',
        name: 'Bemba',
      },
    ],
    inputFiles: [],
    additionalContacts: [],
    startDate: '2024-10-09T18:02:10.816000Z',
    deadline: '2024-11-01T05:00:00.000000Z',
    deliveryDate: null,
    closeDate: null,
    status: 'opened',
    customerSpecialInstructions: '',
    costCenter: null,
    currency: {
      symbol: '$',
      name: 'USD',
    },
    confirmationSentDate: null,
    service: 'Translation + DTP',
    specialization: 'Government',
    rapidFire: null,
    rush: null,
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    feedbacks: [],
    office: 'Beta Client AvantPortal',
    progress: {
      total: 0,
      percentage: '0.00',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    surveySent: null,
    archived: null,
    quoteId: null,
    invoiceId: null,
    invoiceNumber: null,
    tasksForReview: [],
    customFields: null,
    customer: {
      id: '8585',
      name: 'Beta Client AvantPortal',
    },
  },
  {
    id: '178125',
    idNumber: 'MTC-20240223-3',
    refNumber: '4323222',
    name: 'demo project 178',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '83',
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    targetLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    inputFiles: [],
    additionalContacts: [],
    startDate: '2024-08-20T16:56:47.589000Z',
    deadline: '2024-08-30T15:00:00.000000Z',
    deliveryDate: null,
    closeDate: null,
    status: 'opened',
    customerSpecialInstructions: '3243235',
    costCenter: null,
    currency: {
      symbol: '$',
      name: 'USD',
    },
    confirmationSentDate: null,
    service: 'Translation',
    specialization: 'General',
    rapidFire: null,
    rush: null,
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    feedbacks: [],
    office: 'Beta Client AvantPortal',
    progress: {
      total: 0,
      percentage: '0.00',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    surveySent: null,
    archived: null,
    quoteId: null,
    invoiceId: null,
    invoiceNumber: null,
    tasksForReview: [],
    customFields: null,
    customer: {
      id: '8585',
      name: 'Beta Client AvantPortal',
    },
  },
  {
    id: '167114',
    idNumber: 'MTC-20247795-2',
    refNumber: null,
    name: 'demo project 02',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '206',
        symbol: 'ES-US',
        name: 'Spanish (USA)',
      },
    ],
    targetLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
      {
        id: '83',
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    inputFiles: [],
    additionalContacts: [],
    startDate: '2024-08-20T16:32:48.484000Z',
    deadline: '2024-08-29T15:00:00.000000Z',
    deliveryDate: null,
    closeDate: null,
    status: 'opened',
    customerSpecialInstructions: 'Added by QA ContactNew on 08/20/2024 06:33:12:\n<br />new instructions \n<br />\n<br />',
    costCenter: null,
    currency: {
      symbol: '$',
      name: 'USD',
    },
    confirmationSentDate: null,
    service: 'Translation',
    specialization: 'General',
    rapidFire: null,
    rush: null,
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    feedbacks: [],
    office: 'Beta Client AvantPortal',
    progress: {
      total: 0,
      percentage: '0.00',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    surveySent: null,
    archived: null,
    quoteId: null,
    invoiceId: null,
    invoiceNumber: null,
    tasksForReview: [],
    customFields: null,
    customer: {
      id: '8585',
      name: 'Beta Client AvantPortal',
    },
  },
  {
    id: '168890',
    idNumber: 'MTC-20240753-8',
    refNumber: '4323222',
    name: 'demo project 432',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '83',
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    targetLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    inputFiles: [],
    additionalContacts: [],
    startDate: '2024-08-20T16:56:47.589000Z',
    deadline: '2024-08-30T15:00:00.000000Z',
    deliveryDate: null,
    closeDate: null,
    status: 'CLOSED',
    customerSpecialInstructions: '3243235',
    costCenter: null,
    currency: {
      symbol: '$',
      name: 'USD',
    },
    confirmationSentDate: null,
    service: 'Translation',
    specialization: 'General',
    rapidFire: null,
    rush: null,
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    feedbacks: [],
    office: 'Beta Client AvantPortal',
    progress: {
      total: 0,
      percentage: '0.00',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    surveySent: null,
    archived: null,
    quoteId: null,
    invoiceId: null,
    invoiceNumber: null,
    tasksForReview: [],
    customFields: null,
    customer: {
      id: '8585',
      name: 'Beta Client AvantPortal',
    },
  },
  {
    id: '167981',
    idNumber: 'MTC-20240880-7',
    refNumber: null,
    name: 'demo project 167',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '206',
        symbol: 'ES-US',
        name: 'Spanish (USA)',
      },
    ],
    targetLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
      {
        id: '83',
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    inputFiles: [],
    additionalContacts: [],
    startDate: '2024-08-20T16:32:48.484000Z',
    deadline: '2024-08-29T15:00:00.000000Z',
    deliveryDate: null,
    closeDate: null,
    status: 'CLOSED',
    customerSpecialInstructions: 'Added by QA ContactNew on 08/20/2024 06:33:12:\n<br />new instructions \n<br />\n<br />',
    costCenter: null,
    currency: {
      symbol: '$',
      name: 'USD',
    },
    confirmationSentDate: null,
    service: 'Translation',
    specialization: 'General',
    rapidFire: null,
    rush: null,
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    feedbacks: [],
    office: 'Beta Client AvantPortal',
    progress: {
      total: 0,
      percentage: '0.00',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    surveySent: null,
    archived: null,
    quoteId: null,
    invoiceId: null,
    invoiceNumber: null,
    tasksForReview: [],
    customFields: null,
    customer: {
      id: '8585',
      name: 'Beta Client AvantPortal',
    },
  },
]

const templates = [
  {
    id: '92',
    name: 'Template2.0',
    type: 2,
    data: {
      details: {
        name: 'Template 2.0',
        referenceNumber: 'N12342',
        specialization: '5',
        customer: '1459',
        notes: '<p><strong>Special instructions</strong></p><ol><li>asd</li><li>asdasda</li><li>sdadzXvfh</li><li>fdgf</li></ol>',
      },
      service: {
        service: '4',
      },
      additional: {
        additionalContacts: [
          '22566',
        ],
        customFields: {
          purpose: '11',
          source: 'Xtrf',
          li_provider_name: 'Jane Doe',
          invoice_address: 'Contact Person',
          function: 'asdasd',
          rush: true,
          domain: 'Healthcare',
          genre: 'EOC',
        },
      },
      preview: {
        specialization: {
          label: 'General',
          value: '5',
        },
        service: {
          id: '4',
          name: 'Translation + DTP',
          total: 0,
          activityType: 'other',
        },
        sourceLanguage: {
          label: 'French (France)',
          value: '83',
          symbol: 'FR-FR',
        },
        targetLanguages: [
          {
            label: 'Acholi',
            value: '390',
            symbol: 'ACH',
          },
          {
            label: 'Afrikaans',
            value: '3',
            symbol: 'AF',
          },
          {
            label: 'English (Canada)',
            value: '61',
            symbol: 'EN-CA',
          },
        ],
        relativeDeadline: 10,
      },
      languages: {
        sourceLanguage: '117',
        targetLanguages: [
          '61',
          '390',
          '3',
        ],
      },
    },
    owner: true,
  },
]

const templateData = {
  id: '92',
  name: 'Template2.0',
  type: 2,
  data: {
    details: {
      name: 'Template 2.0',
      referenceNumber: 'N12342',
      specialization: '5',
      customer: '1459',
      notes: '<p><strong>Special instructions</strong></p><ol><li>asd</li><li>asdasda</li><li>sdadzXvfh</li><li>fdgf</li></ol>',
    },
    service: {
      service: '4',
    },
    additional: {
      additionalContacts: [
        '22566',
      ],
      customFields: {
        purpose: '11',
        source: 'Xtrf',
        li_provider_name: 'Jane Doe',
        invoice_address: 'Contact Person',
        function: 'asdasd',
        rush: true,
        domain: 'Healthcare',
        genre: 'EOC',
      },
    },
    preview: {
      specialization: {
        label: 'General',
        value: '5',
      },
      service: {
        id: '4',
        name: 'Translation + DTP',
        total: 0,
        activityType: 'other',
      },
      sourceLanguage: [{
        label: 'French (France)',
        value: '83',
        symbol: 'FR-FR',
      }],
      targetLanguages: [
        {
          label: 'Acholi',
          value: '390',
          symbol: 'ACH',
        },
        {
          label: 'Afrikaans',
          value: '3',
          symbol: 'AF',
        },
        {
          label: 'English (Canada)',
          value: '61',
          symbol: 'EN-CA',
        },
      ],
      relativeDeadline: 10,
    },
    languages: {
      sourceLanguage: '83',
      targetLanguages: [
        '61',
        '390',
        '3',
      ],
    },
    files: {
      inputFiles: [
        {
          id: '4029530',
          name: 'download.csv',
        },
      ],
    },
  },
  owner: true,
}

const invoices = [
  {
    id: '338875',
    idNumber: 'IN-X-11111',
    status: 'not_paid',
    fullyPaidDate: null,
    invoiceNote: '',
    paidValue: '0',
    internalStatus: 'sent',
    dueDate: '2024-09-12T00:00:00.000000Z',
    finalDate: '2024-08-13T00:00:00.000000Z',
    totalNetto: '41',
    dueAmount: null,
    customer: 'Beta Client AvantPortal',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    qboId: null,
    tasks: [],
    projectId: '167321',
    projectName: 'testing dsdsffdsdd',
    projectNumber: 'MTC-20241111-6',
  },
  {
    id: '163873',
    idNumber: 'IN-X-82222',
    status: 'not_paid',
    fullyPaidDate: null,
    invoiceNote: '',
    paidValue: '0',
    internalStatus: 'sent',
    dueDate: '2024-09-08T00:00:00.000000Z',
    finalDate: '2024-08-09T00:00:00.000000Z',
    totalNetto: '41',
    dueAmount: null,
    customer: 'Beta Client AvantPortal',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    qboId: null,
    tasks: [],
    projectId: '774423',
    projectName: 'testing dsdsffdsdd',
    projectNumber: 'MTC-20288892-12',
  },
  {
    id: '199891',
    idNumber: 'IN-X-885621',
    status: 'not_paid',
    fullyPaidDate: null,
    invoiceNote: '',
    paidValue: '0',
    internalStatus: 'sent',
    dueDate: '2024-09-08T00:00:00.000000Z',
    finalDate: '2024-08-09T00:00:00.000000Z',
    totalNetto: '31.6',
    dueAmount: null,
    customer: 'Beta Client AvantPortal',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    qboId: null,
    tasks: [],
    projectId: '779351',
    projectName: 'a wdaw',
    projectNumber: 'MTC-20030809-1',
  },
  {
    id: '100669',
    idNumber: 'IN-X-88847',
    status: 'not_paid',
    fullyPaidDate: null,
    invoiceNote: '',
    paidValue: '0',
    internalStatus: 'sent',
    dueDate: '2024-09-08T00:00:00.000000Z',
    finalDate: '2024-08-09T00:00:00.000000Z',
    totalNetto: '41',
    dueAmount: null,
    customer: 'Beta Client AvantPortal',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    qboId: null,
    tasks: [],
    projectId: '165513',
    projectName: 'asdasd',
    projectNumber: 'MTC-20140879-3',
  },
  {
    id: '138984',
    idNumber: 'IN-X-11315',
    status: 'not_paid',
    fullyPaidDate: null,
    invoiceNote: '',
    paidValue: '0',
    internalStatus: 'sent',
    dueDate: '2024-09-07T00:00:00.000000Z',
    finalDate: '2024-08-08T00:00:00.000000Z',
    totalNetto: '17.6',
    dueAmount: null,
    customer: 'Beta Client AvantPortal',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    qboId: null,
    tasks: [],
    projectId: '167000',
    projectName: 'testing dsdsffdsdd',
    projectNumber: 'MTC-23240772-8',
  },
  {
    id: '177006',
    idNumber: 'IN-X-56613',
    status: 'fully_paid',
    fullyPaidDate: null,
    invoiceNote: '',
    paidValue: '0',
    internalStatus: 'sent',
    dueDate: '2024-09-06T00:00:00.000000Z',
    finalDate: '2024-08-07T00:00:00.000000Z',
    totalNetto: '0',
    dueAmount: null,
    customer: 'Beta Client AvantPortal',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    qboId: null,
    tasks: [],
    projectId: '164442',
    projectName: 'testing dsdsffdsdd',
    projectNumber: 'MTC-30560802-13',
    paymentStatus: 'FULLY_PAID',
  },
  {
    id: '796485',
    idNumber: 'IN-X-40593',
    status: 'fully_paid',
    fullyPaidDate: null,
    invoiceNote: '',
    paidValue: '0',
    internalStatus: 'sent',
    dueDate: '2024-04-07T00:00:00.000000Z',
    finalDate: '2024-03-08T00:00:00.000000Z',
    totalNetto: '0',
    dueAmount: null,
    customer: 'Beta Client AvantPortal',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    qboId: null,
    tasks: [],
    projectId: '881147',
    projectName: 'Project1',
    projectNumber: 'MTC-12120308-1',
    paymentStatus: 'FULLY_PAID',
  },
]

const quoteData1 = {
  quote: {
    id: 57896,
    idNumber: 'Q-MTC-20241234-6',
    name: 'TestUser',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        symbol: 'BEM',
        name: 'Bemba',
      },
    ],
    targetLanguages: [
      {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    inputFiles: [
      {
        name: 'sql.drawio.png',
      },
    ],
    startDate: '2024-07-17T08:04:45.139000Z',
    deadline: '2024-07-17T11:30:00.000000Z',
    status: 'requested',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation',
    specialization: 'General',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'QA',
      lastName: 'ContactNew',
    },
    office: 'Beta Client AvantPortal',
  },
  tasks: [
    {
      deadline: '2024-07-17T11:30:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        symbol: 'BEM',
        name: 'Bemba',
      },
      targetLanguage: {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    },
  ],
}

const quoteData2 = {
  quote: {
    id: '54325',
    idNumber: 'Q-MTC-20245432-1',
    name: 'July 12th',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    targetLanguages: [
      {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
      {
        symbol: 'EN-US',
        name: 'English (United States)',
      },
      {
        symbol: 'ET',
        name: 'Estonian',
      },
    ],
    inputFiles: [
      {
        name: 'download (17).csv',
      },
    ],
    startDate: '2024-07-12T18:44:40.778000Z',
    deadline: '2024-07-19T23:00:00.000000Z',
    status: 'requested',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Transcription',
    specialization: 'Government',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'QA',
      lastName: 'ContactNew',
    },
    office: 'Beta Client AvantPortal',
  },
  tasks: [
    {
      deadline: '2024-07-19T23:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        symbol: 'AF',
        name: 'Afrikaans',
      },
      targetLanguage: {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    },
    {
      deadline: '2024-07-19T23:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        symbol: 'AF',
        name: 'Afrikaans',
      },
      targetLanguage: {
        symbol: 'ET',
        name: 'Estonian',
      },
    },
    {
      deadline: '2024-07-19T23:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        symbol: 'AF',
        name: 'Afrikaans',
      },
      targetLanguage: {
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    },
  ],
}

const quoteData3 = {
  quote: {
    id: '96325',
    idNumber: 'Q-MTC-20247413-1',
    name: 'test project 963',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        name: 'English (United States)',
        symbol: 'EN-US',
      },
    ],
    targetLanguages: [
      {
        name: 'English (United States)',
        symbol: 'EN-US',
      },
    ],
    inputFiles: [
      {
        name: 'download.csv',
      },
    ],
    startDate: '2024-07-09T11:44:01.508000Z',
    deadline: '2024-07-12T15:00:00.000000Z',
    status: 'requested',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Transcription',
    specialization: 'General',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'Avantpage',
      lastName: 'Demo',
    },
    office: 'Beta Client AvantPortal',
  },
  tasks: [
    {
      deadline: '2024-07-19T23:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        name: 'English (United States)',
        symbol: 'EN-US',
      },
      targetLanguage: {
        name: 'Chinese (Taiwan/Traditional)',
        symbol: 'ZH-TW',
      },
    },
    {
      deadline: '2024-07-12T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        name: 'English (United States)',
        symbol: 'EN-US',
      },
      targetLanguage: {
        name: 'French (France)',
        symbol: 'FR-FR',
      },
    },
  ],
}

const quoteData4 = {
  quote: {
    id: '74851',
    idNumber: 'Q-MTC-20248896-5',
    name: 'demo project 748',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        name: 'Chinese (Taiwan/Traditional)',
        symbol: 'ZH-TW',
      },
    ],
    targetLanguages: [
      {
        name: 'Bemba',
        symbol: 'BEM',
      },
    ],
    inputFiles: [
      {
        name: 'digital_sign.png',
      },
    ],
    startDate: '2024-06-20T07:38:30.346000Z',
    deadline: '2024-06-27T11:30:00.000000Z',
    status: 'requested',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Transcription',
    specialization: 'Government',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'Avantpage',
      lastName: 'Demo',
    },
    office: 'Beta Client AvantPortal',
  },
  tasks: [
    {
      deadline: '2024-06-27T11:30:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        name: 'Chinese (Taiwan/Traditional)',
        symbol: 'ZH-TW',
      },
      targetLanguage: {
        name: 'Bemba',
        symbol: 'BEM',
      },
    },
    {
      deadline: '2024-07-12T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        name: 'English (United States)',
        symbol: 'EN-US',
      },
      targetLanguage: {
        name: 'French (France)',
        symbol: 'FR-FR',
      },
    },
  ],
}

const quoteData5 = {
  quote: {
    id: '57962',
    idNumber: 'Q-MTC-20247799-4',
    name: 'demo project 579',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        name: 'Afrikaans',
        symbol: 'AF',
      },
    ],
    targetLanguages: [
      {
        name: 'English (United States)',
        symbol: 'EN-US',
      },
    ],
    inputFiles: [],
    startDate: '2024-06-20T07:36:47.180000Z',
    deadline: '2024-06-27T11:30:00.000000Z',
    status: 'requested',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    specialization: 'Government',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'Avantpage',
      lastName: 'Demo',
    },
    office: 'Beta Client AvantPortal',
  },
  tasks: [
    {
      deadline: '2024-06-27T11:30:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        name: 'Afrikaans',
        symbol: 'AF',
      },
      targetLanguage: {
        name: 'English (United States)',
        symbol: 'EN-US',
      },
    },
    {
      deadline: '2024-07-12T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: null,
      sourceLanguage: {
        name: 'English (United States)',
        symbol: 'EN-US',
      },
      targetLanguage: {
        name: 'French (France)',
        symbol: 'FR-FR',
      },
    },
  ],
}

const quoteData6 = {
  quote: {
    id: '55252',
    idNumber: 'Q-MTC-20246644-6',
    refNumber: 'daads',
    name: 'demo project 552',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    targetLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    inputFiles: [],
    customFields: {
      domain: 'Healthcare',
      genre: 'EOC',
      source: 'AvantPortal',
      invoice_address: 'Contact Person',
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-06-20T07:36:47.180000Z',
    deadline: '2024-06-27T11:30:00.000000Z',
    status: 'ACCEPTED',
    customerSpecialInstructions: 'customer instructions 04',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '58123',
      firstName: 'Avantpage',
      lastName: 'Demo',
      email: 'demo@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: 'instructions 04',
    office: 'Beta Client AvantPortal',
    specialization: 'Government',
  },
}

const quoteData7 = {
  quote: {
    id: '25259',
    idNumber: 'Q-MTC-20241122-7',
    refNumber: 'ABCD456',
    name: 'demo project 252',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '3',
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    targetLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    inputFiles: [],
    customFields: {
      domain: 'Healthcare',
      genre: 'EOC',
      source: 'AvantPortal',
      invoice_address: 'Contact Person',
      li_provider_name: 'Jane Doe',
    },
    additionalContacts: [],
    startDate: '2024-06-20T07:36:47.180000Z',
    deadline: '2024-06-27T11:30:00.000000Z',
    status: 'REJECTED',
    customerSpecialInstructions: 'customer instructions 05',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '58123',
      firstName: 'Avantpage',
      lastName: 'Demo',
      email: 'demo@avantpage.com',
    },
    awaitingReview: false,
    projectManagerProfilePic: null,
    accountManagerProfilePic: null,
    projectId: null,
    instructions: 'instructions 05',
    office: 'Beta Client AvantPortal',
    specialization: 'Government',
  },
}

const projectData1 = {
  project: {
    id: 168855,
    idNumber: 'MTC-20242277-1',
    name: 'demo',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    targetLanguages: [
      {
        symbol: 'BEM',
        name: 'Bemba',
      },
    ],
    inputFiles: [
      {
        name: 'taf_ada_fail.pdf',
      },
    ],
    startDate: '2024-10-15T04:38:33.055000Z',
    deadline: '2024-10-30T20:00:00.000000Z',
    status: 'opened',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    specialization: 'General',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'QA',
      lastName: 'ContactNew',
    },
    office: 'Beta Client AvantPortal',
    customerSpecialInstructions: null,
  },
  tasks: [
    {
      deadline: '2024-10-30T20:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: 'MTC-20242277-1-1',
      sourceLanguage: {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
      targetLanguage: {
        symbol: 'BEM',
        name: 'Bemba',
      },
    },
  ],
}

const projectData2 = {
  project: {
    id: 778562,
    idNumber: 'MTC-20299662-1',
    name: 'demo project 778',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    targetLanguages: [
      {
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    inputFiles: [
      {
        name: 'taf_ada_good.pdf',
      },
    ],
    startDate: '2024-10-12T16:32:13.096000Z',
    deadline: '2024-10-30T20:00:00.000000Z',
    status: 'opened',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    specialization: 'General',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'QA',
      lastName: 'ContactNew',
    },
    office: 'Beta Client AvantPortal',
    customerSpecialInstructions: null,
  },
  tasks: [
    {
      deadline: '2024-10-30T20:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: 'MTC-20299662-1-1',
      sourceLanguage: {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
      targetLanguage: {
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    },
  ],
}

const projectData3 = {
  project: {
    id: '177751',
    idNumber: 'MTC-27451009-1',
    refNumber: null,
    name: 'demo project 177',
    totalAgreed: '0.00',
    tmSavings: '0.00',
    sourceLanguages: [
      {
        id: '71',
        symbol: 'EN-US',
        name: 'English (United States)',
      },
    ],
    targetLanguages: [
      {
        id: '391',
        symbol: 'BEM',
        name: 'Bemba',
      },
    ],
    inputFiles: [
      {
        id: '4044816',
        name: 'taf_ada_fail.pdf',
      },
    ],
    startDate: '2024-10-09T18:02:10.816000Z',
    deadline: '2024-11-01T05:00:00.000000Z',
    status: 'opened',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation + DTP',
    specialization: 'Government',
    projectManager: {
      id: '48',
      firstName: 'John',
      lastName: 'Smith',
      email: 'john@avantpage.com',
    },
    requestedBy: {
      id: '11123',
      firstName: 'QA',
      lastName: 'ContactNew',
      email: 'qa.dev@avantpage.com',
    },
    office: 'Beta Client AvantPortal',
    progress: {
      total: 0,
      percentage: '0.00',
    },
    customer: {
      id: '8585',
      name: 'Beta Client AvantPortal',
    },
    customerSpecialInstructions: null,
  },
  tasks: [
    {
      id: '236171',
      activitiesStatus: 'OPENED',
      deadline: '2024-11-01T05:00:00.000000Z',
      projectPhaseIdNumber: 'MTC-27451009-1-1',
      sourceLanguage: {
        symbol: 'EN-US',
        name: 'English (United States)',
      },
      targetLanguage: {
        symbol: 'BEM',
        name: 'Bemba',
      },
      status: 'opened',
      totalAgreed: '0.00',
      tmSavings: '0.00',
      workingFilesNumber: 1,
      progress: {
        total: 0,
        percentage: '0.00',
      },
    },
  ],
}

const projectData4 = {
  project: {
    id: '178125',
    idNumber: 'MTC-20240223-3',
    name: 'demo project 178',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    targetLanguages: [
      {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    inputFiles: [],
    startDate: '2024-08-20T16:56:47.589000Z',
    deadline: '2024-08-30T15:00:00.000000Z',
    status: 'opened',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation',
    specialization: 'General',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'QA',
      lastName: 'ContactNew',
    },
    office: 'Beta Client AvantPortal',
    customerSpecialInstructions: null,
  },
  tasks: [
    {
      deadline: '2024-08-30T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: 'MTC-20240223-3-1',
      sourceLanguage: {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
      targetLanguage: {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    },
  ],
}

const projectData5 = {
  project: {
    id: '167114',
    idNumber: 'MTC-20247795-2',
    name: 'demo project 05',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        symbol: 'ES-US',
        name: 'Spanish (USA)',
      },
    ],
    targetLanguages: [
      {
        symbol: 'AF',
        name: 'Afrikaans',
      },
      {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    inputFiles: [],
    startDate: '2024-08-20T16:32:48.484000Z',
    deadline: '2024-08-29T15:00:00.000000Z',
    status: 'opened',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation',
    specialization: 'General',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'QA',
      lastName: 'ContactNew',
    },
    office: 'Beta Client AvantPortal',
    customerSpecialInstructions: null,
  },
  tasks: [
    {
      deadline: '2024-08-29T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: 'MTC-20247795-2-1',
      sourceLanguage: {
        symbol: 'ES-US',
        name: 'Spanish (USA)',
      },
      targetLanguage: {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    },
    {
      deadline: '2024-08-29T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: 'MTC-20247795-2-2',
      sourceLanguage: {
        symbol: 'ES-US',
        name: 'Spanish (USA)',
      },
      targetLanguage: {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    },
  ],
}

const projectData6 = {
  project: {
    id: '168890',
    idNumber: 'MTC-20240223-3',
    name: 'demo project 168',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    targetLanguages: [
      {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    ],
    inputFiles: [],
    startDate: '2024-08-20T16:56:47.589000Z',
    deadline: '2024-08-30T15:00:00.000000Z',
    status: 'opened',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation',
    specialization: 'General',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'QA',
      lastName: 'ContactNew',
    },
    office: 'Beta Client AvantPortal',
    customerSpecialInstructions: null,
  },
  tasks: [
    {
      deadline: '2024-08-30T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: 'MTC-20240223-3-1',
      sourceLanguage: {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
      targetLanguage: {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    },
  ],
}

const projectData7 = {
  project: {
    id: '167981',
    idNumber: 'MTC-20247795-2',
    name: 'demo project 07',
    totalAgreed: '0.00',
    sourceLanguages: [
      {
        symbol: 'ES-US',
        name: 'Spanish (USA)',
      },
    ],
    targetLanguages: [
      {
        symbol: 'AF',
        name: 'Afrikaans',
      },
      {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    ],
    inputFiles: [],
    startDate: '2024-08-20T16:32:48.484000Z',
    deadline: '2024-08-29T15:00:00.000000Z',
    status: 'opened',
    currency: {
      symbol: '$',
      name: 'USD',
    },
    service: 'Translation',
    specialization: 'General',
    projectManager: {
      firstName: 'John',
      lastName: 'Smith',
    },
    requestedBy: {
      firstName: 'QA',
      lastName: 'ContactNew',
    },
    office: 'Beta Client AvantPortal',
    customerSpecialInstructions: null,
  },
  tasks: [
    {
      deadline: '2024-08-29T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: 'MTC-20247795-2-1',
      sourceLanguage: {
        symbol: 'ES-US',
        name: 'Spanish (USA)',
      },
      targetLanguage: {
        symbol: 'AF',
        name: 'Afrikaans',
      },
    },
    {
      deadline: '2024-08-29T15:00:00.000000Z',
      progress: {
        percentage: '0.00',
      },
      totalAgreed: '0.00',
      projectPhaseIdNumber: 'MTC-20247795-2-2',
      sourceLanguage: {
        symbol: 'ES-US',
        name: 'Spanish (USA)',
      },
      targetLanguage: {
        symbol: 'FR-FR',
        name: 'French (France)',
      },
    },
  ],
}

const gallery = [
  {
    graph: {
      id: 'a7416af9-6e2c-42be-9182-c1c59c76e475',
      slug: 'tasks-rush-language',
      name: 'Tasks rush language',
      description: 'return Tasks rush language',
      category: 'finance',
      type: 8,
      returnY: 'total',
      active: true,
      size: 12,
      options: null,
      reportType: 32,
    },
    id: 'a7416af9-6e2c-42be-9182-c1c59c76e475',
    active: true,
  },
  {
    graph: {
      id: 'ae219ddf-0000-4471-95ed-94fdfaf52233',
      slug: 'words-breakdown',
      name: 'Words breakdown',
      description: 'return words breakdown',
      category: 'finance',
      type: 8,
      returnY: null,
      active: true,
      size: 12,
      options: null,
      reportType: 12,
    },
    id: 'ae219ddf-0000-4471-95ed-94fdfaf52233',
    active: false,
  },
  {
    graph: {
      id: 'a7456af9-6e2c-42be-8585-c1c59c76e123',
      slug: 'projects-comparison-year',
      name: 'Project comparison year',
      description: 'return Project comparison year',
      category: 'finance',
      type: 7,
      returnY: 'total',
      active: true,
      size: 12,
      options: null,
      reportType: 22,
    },
    id: 'a7456af9-6e2c-42be-8585-c1c59c76e123',
    active: true,
  },
  {
    graph: {
      id: 'a3336af9-6e2c-42be-1111-c1c59c76e000',
      slug: 'spend-comparison',
      name: 'Spend comparison',
      description: 'return spend comparison',
      category: 'finance',
      type: 7,
      returnY: 'total',
      active: true,
      size: 12,
      options: null,
      reportType: 29,
    },
    id: 'a3336af9-6e2c-42be-1111-c1c59c76e000',
    active: false,
  },
  {
    graph: {
      id: 'd5ab3b85-6ceb-6060-b309-0f7a8f4cf4e4',
      slug: 'spend-historical',
      name: 'Spend historical',
      description: 'return spend historicals',
      category: 'finance',
      type: 9,
      returnY: 'total',
      active: true,
      size: 12,
      options: null,
      reportType: 13,
    },
    id: 'd5ab3b85-6ceb-6060-b309-0f7a8f4cf4e4',
    active: true,
  },
  {
    graph: {
      id: '5bd877b9-2d3c-4ebe-1111-0ecf9f524545',
      slug: 'spend-projects',
      name: 'Spend projects',
      description: 'return Spend projects',
      category: 'finance',
      type: 4,
      returnY: null,
      active: true,
      size: 12,
      options: null,
      reportType: 47,
    },
    id: '5bd877b9-2d3c-4ebe-1111-0ecf9f524545',
    active: false,
  },
]

const galleryPostData = {
  data: [
    {
      id: 'a7456af9-6e2c-42be-8585-c1c59c76e123',
      slug: 'projects-comparison-year',
      name: 'Project comparison year',
      type: '7',
      description: 'return Project comparison year',
      category: 'projects',
      options: {
        x: 0,
        y: 0,
        w: 1,
        h: 6,
      },
    },
    {
      id: 'd5ab3b85-6ceb-6060-b309-0f7a8f4cf4e4',
      slug: 'spend-historical',
      name: 'Spend historical',
      type: '9',
      description: 'return spend historicals',
      category: 'finance',
      options: {
        x: 1,
        y: 0,
        w: 1,
        h: 6,
      },
    },
    {
      id: 'a7416af9-6e2c-42be-9182-c1c59c76e475',
      slug: 'tasks-rush-language',
      name: 'Tasks rush language',
      type: '8',
      description: 'return Tasks rush language',
      category: 'finance',
      options: {
        x: 1,
        y: 6,
        w: 1,
        h: 6,
      },
    },
    {
      id: 'ae219ddf-0000-4471-95ed-94fdfaf52233',
      slug: 'words-breakdown',
      name: 'Words breakdown',
      type: '8',
      description: 'return words breakdown',
      category: 'finance',
      options: {
        x: 0,
        y: 6,
        w: 1,
        h: 6,
      },
    },
    {
      id: 'a3336af9-6e2c-42be-1111-c1c59c76e000',
      slug: 'spend-comparison',
      name: 'Spend comparison',
      type: '7',
      description: 'return spend comparison',
      category: 'finance',
      options: {
        x: 0,
        y: 12,
        w: 1,
        h: 6,
      },
    },
    {
      id: '5bd877b9-2d3c-4ebe-1111-0ecf9f524545',
      slug: 'spend-projects',
      name: 'Spend projects',
      type: '4',
      description: 'return Spend projects',
      category: 'finance',
      options: {
        x: 0,
        y: 18,
        w: 1,
        h: 6,
      },
    },
  ],
}

const wordsBreakdownChart = {
  series: [
    {
      name: 'Tasks',
      data: [
        {
          name: 'Spanish (Puerto Rico)',
          y: 1,
        },
        {
          name: 'Spanish (Spain)',
          y: 1,
        },
        {
          name: 'Spanish (USA)',
          y: 1,
        },
      ],
    },
  ],
}

const spendComparisonChart = {
  categories: [
    '2023 Sep-2024 Sep',
    '2023 Oct-2024 Oct',
  ],
  series: [
    {
      name: 'Previous Period',
      data: [
        0,
        2,
      ],
    },
    {
      name: 'Current Period',
      data: [
        0,
        2,
      ],
    },
  ],
}

const spendProjectsChart = {
  categories: [
    'Nov 2023',
    'Dec 2023',
    'Jan 2024',
    'Feb 2024',
    'Mar 2024',
    'Apr 2024',
    'May 2024',
    'Jun 2024',
    'Jul 2024',
    'Aug 2024',
    'Sep 2024',
    'Oct 2024',
  ],
  series: [
    {
      name: 'Projects',
      data: [
        { name: 'Nov 2023', y: 0 },
        { name: 'Dec 2023', y: 0 },
        { name: 'Jan 2024', y: 0 },
        { name: 'Feb 2024', y: 0 },
        { name: 'Mar 2024', y: 0 },
        { name: 'Apr 2024', y: 26059.01 },
        { name: 'May 2024', y: 0 },
        { name: 'Jun 2024', y: 0 },
        { name: 'Jul 2024', y: 0 },
        { name: 'Aug 2024', y: 324.6 },
        { name: 'Sep 2024', y: 0 },
        { name: 'Oct 2024', y: 0 },
      ],
    },
  ],
}

const resourcesSpecializations = [
  {
    value: 5,
    label: 'General',
  },
  {
    value: 18,
    label: 'Government',
  },
  {
    value: 16,
    label: 'HealthCare',
  },
  {
    value: 7,
    label: 'IT',
  },
]

const resourcesSetting = {
  projects: {
    features: {
      workingFilesAsRefFiles: true,
      updateWorkingFiles: true,
      confirmationSendByDefault: true,
      downloadConfirmation: true,
      deadlineOptions: [
        1,
        2,
        5,
        10,
      ],
      analyzeFiles: true,
      duplicateTask: true,
      quickEstimate: true,
      deadlinePrediction: true,
      autoStart: true,
      maxFileSize: 128,
      filesQueue: 'projects_files_normal',
      rushDeadline: 24,
      categories: [
        '60',
      ],
      fileExtensionsWarning: [],
      dearchive: false,
    },
    customFields: [
      {
        name: 'rush',
        enabled: true,
        visible: true,
        value: false,
      },
      {
        name: 'invoice_notes',
        enabled: true,
        visible: true,
        value: false,
      },
      {
        name: 'cost_center',
        enabled: true,
        visible: true,
      },
      {
        name: 'nuid',
        enabled: true,
        visible: true,
      },
      {
        name: 'audience',
        enabled: true,
        visible: true,
      },
      {
        name: 'purpose',
        enabled: true,
        visible: true,
      },
      {
        name: 'function',
        enabled: true,
        visible: true,
      },
      {
        name: 'domain',
        enabled: true,
        visible: true,
        value: 'Healthcare',
      },
      {
        name: 'genre',
        enabled: true,
        visible: true,
        value: 'EOC',
      },
      {
        name: 'otn_number',
        enabled: true,
        visible: true,
      },
      {
        name: 'li_provider_name',
        enabled: true,
        visible: true,
        value: 'Jane Doe',
      },
      {
        name: 'billing_contact',
        enabled: true,
        visible: true,
      },
      {
        name: 'invoice_address',
        enabled: true,
        visible: true,
        value: 'Contact Person',
      },
    ],
  },
  quotes: {
    workingFilesAsRefFiles: true,
    updateWorkingFiles: true,
    confirmationSendByDefault: false,
    downloadConfirmation: true,
    deadlineOptions: [],
    analyzeFiles: true,
    duplicateTask: true,
    quickEstimate: false,
    deadlinePrediction: false,
    customFields: {
      general: {
        sendSource: {
          type: 'CHECKBOX',
          name: 'Send Source Files',
          key: 'send_source',
          default: false,
          scope: [
            'projects',
            'quotes',
          ],
          enabled: false,
          visible: false,
        },
        rush: {
          type: 'CHECKBOX',
          name: 'RUSH',
          key: 'rush',
          default: false,
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
      },
      billing: {
        nuid: {
          type: 'TEXT',
          name: 'Billing NUID',
          key: 'nuid',
          default: '',
          scope: [
            'projects',
            'quotes',
          ],
          enabled: false,
          visible: false,
        },
        billingContact: {
          type: 'TEXT',
          name: 'Billing Contact ',
          key: 'billing_contact',
          default: '',
          scope: [
            'projects',
            'quotes',
          ],
          enabled: false,
          visible: false,
        },
        costCenter: {
          type: 'TEXT',
          name: 'Cost Center',
          key: 'cost_center',
          default: '',
          scope: [
            'projects',
            'quotes',
          ],
          enabled: false,
          visible: false,
        },
        liProviderName: {
          type: 'SELECTION',
          name: 'L&I Provider Name',
          key: 'li_provider_name',
          default: '',
          scope: [
            'projects',
            'quotes',
          ],
          options: 'providerNameOptions',
          enabled: false,
          visible: false,
        },
      },
      instructions: {
        audience: {
          type: 'TEXT',
          name: 'Audience',
          key: 'audience',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        function: {
          type: 'TEXT',
          name: 'Function',
          key: 'function',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        genre: {
          type: 'SELECTION',
          name: 'Genre',
          key: 'genre',
          default: '',
          scope: [
            'projects',
          ],
          options: 'genreOptions',
          enabled: false,
          visible: false,
        },
        domain: {
          type: 'SELECTION',
          name: 'Domain',
          key: 'domain',
          default: '',
          scope: [
            'projects',
          ],
          options: 'domainOptions',
          enabled: false,
          visible: false,
        },
        purpose: {
          type: 'TEXT',
          name: 'Purpose',
          key: 'purpose',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
      },
      others: {
        template: {
          type: 'SELECTION',
          name: 'Template',
          key: 'template',
          default: '',
          scope: [
            'projects',
          ],
          options: 'templateOptions',
          enabled: false,
          visible: false,
        },
        error: {
          type: 'TEXT',
          name: 'Error',
          key: 'error',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        invoiceAddress: {
          type: 'SELECTION',
          name: 'Invoice Address',
          key: 'invoice_address',
          default: '',
          scope: [
            'projects',
          ],
          options: 'invoiceAddressOptions',
          enabled: false,
          visible: false,
        },
        invoiceNotes: {
          type: 'CHECKBOX',
          name: 'Invoice Notes',
          key: 'invoice_notes',
          default: false,
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        rapidFire: {
          type: 'SELECTION',
          name: 'Rapid-Fire',
          key: 'rapid_fire',
          default: '',
          scope: [
            'projects',
          ],
          options: 'rapidFireOptions',
          enabled: false,
          visible: false,
        },
        otnNumber: {
          type: 'TEXT',
          name: 'OTN Number',
          key: 'otn_number',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        prAccStatus: {
          type: 'TEXT',
          name: 'PR- AccStatus',
          key: 'pr_acc_status',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        quoteAddress: {
          type: 'SELECTION',
          name: 'Quote Address',
          key: 'quote_address',
          default: '',
          scope: [
            'quotes',
          ],
          options: 'quoteAddressOptions',
          enabled: false,
          visible: false,
        },
      },
    },
  },
  invoices: {
    onlinePayment: false,
  },
  reports: {
    predefinedData: {
      name: 'Report Name',
      categories: [
        {
          id: 'widgets',
          title: 'Your dashboard',
          description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
        },
        {
          id: 'projects',
          title: 'Projects',
          description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
        },
        {
          id: 'finance',
          title: 'Finance',
          description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
        },
        {
          id: 'quality',
          title: 'Quality',
          description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
        },
      ],
    },
  },
  general: {
    teamsWebhook: null,
  },
}

const resourcesRejectReasons = [
  {
    id: '1',
    name: 'Too expensive',
  },
  {
    id: '2',
    name: 'Walked away',
  },
  {
    id: '3',
    name: 'Alternative won',
  },
  {
    id: '4',
    name: 'Other',
  },
  {
    id: '5',
    name: 'Never heard back',
  },
  {
    id: '6',
    name: 'Project cancelled',
  },
]

const resourcesSettings = {
  projects: {
    features: {
      workingFilesAsRefFiles: true,
      updateWorkingFiles: true,
      confirmationSendByDefault: true,
      downloadConfirmation: true,
      deadlineOptions: [
        1,
        2,
        5,
        10,
      ],
      analyzeFiles: true,
      duplicateTask: true,
      quickEstimate: true,
      deadlinePrediction: true,
      autoStart: true,
      maxFileSize: 128,
      filesQueue: 'projects_files_normal',
      rushDeadline: 24,
      categories: [
        '60',
      ],
      fileExtensionsWarning: [],
      dearchive: false,
    },
    customFields: [
      {
        name: 'rush',
        enabled: true,
        visible: true,
        value: false,
      },
      {
        name: 'invoice_notes',
        enabled: true,
        visible: true,
        value: false,
      },
      {
        name: 'cost_center',
        enabled: true,
        visible: true,
      },
      {
        name: 'nuid',
        enabled: true,
        visible: true,
      },
      {
        name: 'audience',
        enabled: true,
        visible: true,
      },
      {
        name: 'purpose',
        enabled: true,
        visible: true,
      },
      {
        name: 'function',
        enabled: true,
        visible: true,
      },
      {
        name: 'domain',
        enabled: true,
        visible: true,
        value: 'Healthcare',
      },
      {
        name: 'genre',
        enabled: true,
        visible: true,
        value: 'EOC',
      },
      {
        name: 'otn_number',
        enabled: true,
        visible: true,
      },
      {
        name: 'li_provider_name',
        enabled: true,
        visible: true,
        value: 'Jane Doe',
      },
      {
        name: 'billing_contact',
        enabled: true,
        visible: true,
      },
      {
        name: 'invoice_address',
        enabled: true,
        visible: true,
        value: 'Contact Person',
      },
    ],
  },
  quotes: {
    workingFilesAsRefFiles: true,
    updateWorkingFiles: true,
    confirmationSendByDefault: false,
    downloadConfirmation: true,
    deadlineOptions: [],
    analyzeFiles: true,
    duplicateTask: true,
    quickEstimate: false,
    deadlinePrediction: false,
    customFields: {
      general: {
        sendSource: {
          type: 'CHECKBOX',
          name: 'Send Source Files',
          key: 'send_source',
          default: false,
          scope: [
            'projects',
            'quotes',
          ],
          enabled: false,
          visible: false,
        },
        rush: {
          type: 'CHECKBOX',
          name: 'RUSH',
          key: 'rush',
          default: false,
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
      },
      billing: {
        nuid: {
          type: 'TEXT',
          name: 'Billing NUID',
          key: 'nuid',
          default: '',
          scope: [
            'projects',
            'quotes',
          ],
          enabled: false,
          visible: false,
        },
        billingContact: {
          type: 'TEXT',
          name: 'Billing Contact ',
          key: 'billing_contact',
          default: '',
          scope: [
            'projects',
            'quotes',
          ],
          enabled: false,
          visible: false,
        },
        costCenter: {
          type: 'TEXT',
          name: 'Cost Center',
          key: 'cost_center',
          default: '',
          scope: [
            'projects',
            'quotes',
          ],
          enabled: false,
          visible: false,
        },
        liProviderName: {
          type: 'SELECTION',
          name: 'L&I Provider Name',
          key: 'li_provider_name',
          default: '',
          scope: [
            'projects',
            'quotes',
          ],
          options: 'providerNameOptions',
          enabled: false,
          visible: false,
        },
      },
      instructions: {
        audience: {
          type: 'TEXT',
          name: 'Audience',
          key: 'audience',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        function: {
          type: 'TEXT',
          name: 'Function',
          key: 'function',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        genre: {
          type: 'SELECTION',
          name: 'Genre',
          key: 'genre',
          default: '',
          scope: [
            'projects',
          ],
          options: 'genreOptions',
          enabled: false,
          visible: false,
        },
        domain: {
          type: 'SELECTION',
          name: 'Domain',
          key: 'domain',
          default: '',
          scope: [
            'projects',
          ],
          options: 'domainOptions',
          enabled: false,
          visible: false,
        },
        purpose: {
          type: 'TEXT',
          name: 'Purpose',
          key: 'purpose',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
      },
      others: {
        template: {
          type: 'SELECTION',
          name: 'Template',
          key: 'template',
          default: '',
          scope: [
            'projects',
          ],
          options: 'templateOptions',
          enabled: false,
          visible: false,
        },
        error: {
          type: 'TEXT',
          name: 'Error',
          key: 'error',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        invoiceAddress: {
          type: 'SELECTION',
          name: 'Invoice Address',
          key: 'invoice_address',
          default: '',
          scope: [
            'projects',
          ],
          options: 'invoiceAddressOptions',
          enabled: false,
          visible: false,
        },
        invoiceNotes: {
          type: 'CHECKBOX',
          name: 'Invoice Notes',
          key: 'invoice_notes',
          default: false,
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        rapidFire: {
          type: 'SELECTION',
          name: 'Rapid-Fire',
          key: 'rapid_fire',
          default: '',
          scope: [
            'projects',
          ],
          options: 'rapidFireOptions',
          enabled: false,
          visible: false,
        },
        otnNumber: {
          type: 'TEXT',
          name: 'OTN Number',
          key: 'otn_number',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        prAccStatus: {
          type: 'TEXT',
          name: 'PR- AccStatus',
          key: 'pr_acc_status',
          default: '',
          scope: [
            'projects',
          ],
          enabled: false,
          visible: false,
        },
        quoteAddress: {
          type: 'SELECTION',
          name: 'Quote Address',
          key: 'quote_address',
          default: '',
          scope: [
            'quotes',
          ],
          options: 'quoteAddressOptions',
          enabled: false,
          visible: false,
        },
      },
    },
  },
  invoices: {
    onlinePayment: false,
  },
  reports: {
    predefinedData: {
      name: 'Report Name',
      categories: [
        {
          id: 'widgets',
          title: 'Your dashboard',
          description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
        },
        {
          id: 'projects',
          title: 'Projects',
          description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
        },
        {
          id: 'finance',
          title: 'Finance',
          description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
        },
        {
          id: 'quality',
          title: 'Quality',
          description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus repellat laudantium.',
        },
      ],
    },
  },
  general: {
    teamsWebhook: null,
  },
}

const resourcesSettingsSchema = {
  features: {
    projects: [
      {
        name: 'autoStart',
        type: 'toggle',
      },
      {
        name: 'quickEstimate',
        type: 'toggle',
      },
      {
        name: 'workingFilesAsRefFiles',
        type: 'toggle',
      },
      {
        name: 'updateWorkingFiles',
        type: 'toggle',
      },
      {
        name: 'downloadConfirmation',
        type: 'toggle',
      },
      {
        name: 'duplicateTask',
        type: 'toggle',
      },
      {
        name: 'analyzeFiles',
        type: 'toggle',
      },
      {
        name: 'dearchive',
        type: 'toggle',
      },
      {
        name: 'maxFileSize',
        type: 'number',
      },
      {
        name: 'filesQueue',
        type: 'dropdown',
        options: [
          'projects_files_normal',
          'projects_files_high',
          'projects_files_urgent',
        ],
      },
      {
        name: 'deadlinePrediction',
        type: 'toggle',
      },
      {
        name: 'deadlineOptions',
        type: 'taglist',
        options: [
          1,
          2,
          5,
          10,
        ],
      },
      {
        name: 'fileExtensionsWarning',
        type: 'taglist',
        localized: true,
        options: [
          'pdf',
          'jpg',
          'png',
          'indd',
          'wav',
          'mp3',
          'mp4',
          'avi',
          'mov',
          'zip',
        ],
      },
      {
        name: 'categories',
        type: 'taglist',
        localized: true,
        options: [],
      },
    ],
    invoices: [
      {
        name: 'onlinePayment',
        type: 'toggle',
      },
    ],
  },
  customFields: [
    {
      type: 'text',
      name: 'purpose',
      category: 'instructions',
    },
    {
      type: 'checkbox',
      name: 'rush',
      category: 'workflow',
    },
    {
      type: 'checkbox',
      name: 'invoice_notes',
      category: 'billing',
    },
    {
      type: 'text',
      name: 'pr_acc_status',
      category: 'deprecated',
    },
    {
      type: 'text',
      name: 'function',
      category: 'instructions',
    },
    {
      type: 'text',
      name: 'cost_center',
      category: 'billing',
    },
    {
      type: 'text',
      name: 'audience',
      category: 'instructions',
    },
    {
      type: 'text',
      name: 'otn_number',
      category: 'billing',
    },
    {
      type: 'number',
      name: 'working_days',
      category: '',
    },
    {
      type: 'checkbox',
      name: 'send_source',
      category: 'workflow',
    },
    {
      type: 'text',
      name: 'nuid',
      category: 'billing',
    },
    {
      type: 'select',
      name: 'domain',
      category: 'instructions',
      options: [
        'Healthcare',
        'Elections',
        'Government services',
        'Education',
        'Healthcare Marketing',
        'Social Services',
        'Other domain',
        'Medical',
      ],
    },
    {
      type: 'select',
      name: 'li_provider_name',
      category: 'billing',
      options: [
        'Jane, Doe',
        'John, Smith',
        'Willians, Marc',
        'Jones, Jessie',
      ],
    },
    {
      type: 'select',
      name: 'genre',
      category: 'instructions',
      options: [
        'EOC',
        'SBC',
        'ANOC',
        'Directory',
        'Ballot',
        'Candidate Statement',
        'Voting Initiatives / Amendments / Propositions / M',
        'Web copy',
        'Facility sign',
        'PPT / Training',
        'Voter Information Guide',
        'Educational Test / Assessment',
        'Meeting Materials',
        'Marketing Materials',
        'Print Announcement',
        'Letter',
        'Policy',
        'Brochure/flyer/poster',
        'Survey',
        'Script',
        'Medical Record',
        'Vital Records',
        'Form',
        'Other genre',
        'Text of Proposed Laws',
        'Letter Template',
        'Health Education',
        'Newsletters',
      ],
    },
    {
      type: 'text',
      name: 'billing_contact',
      category: 'billing',
    },
    {
      type: 'select',
      name: 'invoice_address',
      category: 'billing',
      options: [
        'Contact Person',
        'Customer',
        'Mailing',
      ],
    },
    {
      type: 'select',
      name: 'source',
      category: 'workflow',
      options: [
        'Xtrf',
        'AvantPortal',
        'AvantWorkflows',
      ],
    },
    {
      type: 'select',
      name: 'rapid_fire',
      category: 'workflow',
      options: [
        'Rapid-Fire-1',
        'Rapid-Fire-KCM',
        'Rapid-Fire-Covid19',
        'Rapid-Fire-KP',
      ],
    },
    {
      type: 'select',
      name: 'document_type',
      category: '',
      options: [
        'MA',
        'MR',
        'CP',
        'Part C',
        'Part D',
      ],
    },
  ],
}

const resourcesServices = {
  top: [
    {
      id: '1',
      name: 'Translation',
      type: 'translation',
    },
    {
      id: '4',
      name: 'Translation + DTP',
      type: 'translation',
    },
    {
      id: '114',
      name: 'Transcription',
      type: 'transcription',
    },
    {
      id: '79',
      name: 'ADA',
      type: 'other',
    },
  ],
  all: [
    {
      id: '79',
      name: 'ADA',
      type: 'other',
    },
    {
      id: '4',
      name: 'Translation + DTP',
      type: 'translation',
    },
    {
      id: '114',
      name: 'Transcription',
      type: 'transcription',
    },
    {
      id: '1',
      name: 'Translation',
      type: 'translation',
    },
  ],
}

const resourcesPriceProfiles = [
  {
    id: '2037',
    name: 'Pricelist [$]',
  },
  {
    id: '2039',
    name: 'For testing',
  },
]

const resourcesLanguages = {
  top: [
    {
      id: '48',
      symbol: 'ZH-TW',
      name: 'Chinese (Taiwan/Traditional)',
    },
    {
      id: '83',
      symbol: 'FR-FR',
      name: 'French (France)',
    },
    {
      id: '74',
      symbol: 'ET',
      name: 'Estonian',
    },
  ],
  all: [
    {
      id: '3',
      symbol: 'AF',
      name: 'Afrikaans',
    },
    {
      id: '391',
      symbol: 'BEM',
      name: 'Bemba',
    },
    {
      id: '48',
      symbol: 'ZH-TW',
      name: 'Chinese (Taiwan/Traditional)',
    },
    {
      id: '71',
      symbol: 'EN-US',
      name: 'English (United States)',
    },
    {
      id: '74',
      symbol: 'ET',
      name: 'Estonian',
    },
    {
      id: '83',
      symbol: 'FR-FR',
      name: 'French (France)',
    },
    {
      id: '206',
      symbol: 'ES-US',
      name: 'Spanish (USA)',
    },
  ],
}

const resourcesDeadlines = {
  options: [
    1,
    2,
    5,
    10,
  ],
  prediction: null,
  rush: 24,
  holidays: [
    '2024-11-28',
    '2024-11-29',
    '2024-12-23',
    '2024-12-24',
    '2024-12-25',
  ],
}

export default {
  columnMonthData,
  columnQuarterData,
  columnYearData,
  linearMonthData,
  linearQuarterData,
  linearYearData,
  columnLast7DaysMonthData,
  columnLast30DaysMonthData,
  columnLast90DaysMonthData,
  linearLast7DaysMonthData,
  linearLast30DaysMonthData,
  linearLast90DaysMonthData,
  columnLast7DaysQuarterData,
  columnLast30DaysQuarterData,
  columnLast90DaysQuarterData,
  linearLast7DaysQuarterData,
  linearLast30DaysQuarterData,
  linearLast90DaysQuarterData,
  columnLast7DaysYearData,
  columnLast30DaysYearData,
  columnLast90DaysYearData,
  linearLast7DaysYearData,
  linearLast30DaysYearData,
  linearLast90DaysYearData,
  projectData1,
  projectData2,
  projectData3,
  projectData4,
  projectData5,
  projectData6,
  projectData7,
  quotes,
  quoteData1,
  quoteData2,
  quoteData3,
  quoteData4,
  quoteData5,
  quoteData6,
  quoteData7,
  gallery,
  wordsBreakdownChart,
  spendComparisonChart,
  spendProjectsChart,
  projects,
  templates,
  invoices,
  resourcesSpecializations,
  resourcesSetting,
  resourcesServices,
  resourcesPriceProfiles,
  resourcesLanguages,
  resourcesDeadlines,
  dashboardData,
  galleryPostData,
  templateData,
  resourcesSettingsSchema,
  resourcesSettings,
  members,
  resourcesRejectReasons,
}
