/* eslint-disable no-case-declarations */
import type { Ref } from 'vue'
import dummyData from '../mocks/dummyData'

const {
  dashboardData,
  columnMonthData,
  columnQuarterData,
  columnYearData,
  linearMonthData,
  linearQuarterData,
  linearYearData,
  columnLast7DaysMonthData,
  columnLast30DaysMonthData,
  columnLast90DaysMonthData,
  linearLast7DaysMonthData,
  linearLast30DaysMonthData,
  linearLast90DaysMonthData,
  columnLast7DaysQuarterData,
  columnLast30DaysQuarterData,
  columnLast90DaysQuarterData,
  linearLast7DaysQuarterData,
  linearLast30DaysQuarterData,
  linearLast90DaysQuarterData,
  columnLast7DaysYearData,
  columnLast30DaysYearData,
  columnLast90DaysYearData,
  linearLast7DaysYearData,
  linearLast30DaysYearData,
  linearLast90DaysYearData,
  projectData1,
  projectData2,
  projectData3,
  projectData4,
  projectData5,
  projectData6,
  projectData7,
  quotes,
  quoteData1,
  quoteData2,
  quoteData3,
  quoteData4,
  quoteData5,
  gallery,
  wordsBreakdownChart,
  spendComparisonChart,
  spendProjectsChart,
  projects,
  templates,
  invoices,
  resourcesSpecializations,
  resourcesSetting,
  resourcesServices,
  resourcesPriceProfiles,
  resourcesLanguages,
  resourcesDeadlines,
  resourcesRejectReasons,
  galleryPostData,
  templateData,
  resourcesSettingsSchema,
  resourcesSettings,
  members,
  quoteData6,
  quoteData7,
} = dummyData

const calculateDateDifference = (date1: string | Date, date2: string | Date): number => {
  const diffTime = Math.abs(new Date(date2).getTime() - new Date(date1).getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const useDemoStore = defineStore('demoStore', () => {
  const { data: authData } = useAuth()

  // const { ability } = usePermissions()
  // const isDemoMode = computed(() => !ability.can('manage', 'all') && ability.can('manage', 'account-demo'))
  // TODO: Backend is mixing the user roles/abilities with customer roles/abilities, so we need to check for 'DEMO' role directly, this is a workaround if backend is fixed uncomment the above line and remove the following
  const isDemoMode = computed(() => authData.value?.user?.roles?.includes('ROLE_CP_DEMO'))

  const baseProjects: Ref<typeof projects> = ref(projects)
  const baseQuotes: Ref<typeof quotes> = ref(quotes)
  const baseGallery: Ref<typeof gallery> = ref(gallery)
  const baseTemplates: Ref<typeof templates> = ref(templates)
  const baseTemplate = ref(templateData)
  const projectData1Ref = ref(projectData1)
  const projectData2Ref = ref(projectData2)
  const projectData3Ref = ref(projectData3)
  const projectData4Ref = ref(projectData4)
  const projectData5Ref = ref(projectData5)
  const projectData6Ref = ref(projectData6)
  const projectData7Ref = ref(projectData7)

  async function mockGetRequest(endpoint: string, params: any) {
    switch (endpoint) {
      case 'projects':
        if (params?.status[0] === 'CLOSED') {
          return {
            data: baseProjects.value.filter(project => project.status === 'CLOSED'),
          }
        }
        return {
          data: baseProjects.value,
        }
      case 'dashboard':
        return {
          data: dashboardData,
        }
      case 'dashboard/graphs/a7456af9-6e2c-42be-8585-c1c59c76e123':
        let graphData
        const graphGroupBy = params?.groupBy || 'month'
        const betweenDates1 = params?.between
        let dateDifference1 = 0

        if (betweenDates1) {
          const [startDate, endDate] = betweenDates1
          dateDifference1 = calculateDateDifference(startDate, endDate)
        }

        // Determine data based on date difference and groupBy value
        if (dateDifference1 <= 7) {
          switch (graphGroupBy) {
            case 'quarter':
              graphData = columnLast7DaysQuarterData
              break
            case 'year':
              graphData = columnLast7DaysYearData
              break
            default:
              graphData = columnLast7DaysMonthData
          }
        }
        else if (dateDifference1 <= 30) {
          switch (graphGroupBy) {
            case 'quarter':
              graphData = columnLast30DaysQuarterData
              break
            case 'year':
              graphData = columnLast30DaysYearData
              break
            default:
              graphData = columnLast30DaysMonthData
          }
        }
        else if (dateDifference1 <= 90) {
          switch (graphGroupBy) {
            case 'quarter':
              graphData = columnLast90DaysQuarterData
              break
            case 'year':
              graphData = columnLast90DaysYearData
              break
            default:
              graphData = columnLast90DaysMonthData
          }
        }
        else {
          // If no 'between' provided or it exceeds 90 days, fallback to default graphData by groupBy
          switch (graphGroupBy) {
            case 'quarter':
              graphData = columnQuarterData
              break
            case 'year':
              graphData = columnYearData
              break
            default:
              graphData = columnMonthData // Default to month data
          }
        }

        return { data: graphData }
      case 'dashboard/graphs/d5ab3b85-6ceb-6060-b309-0f7a8f4cf4e4':
        let data
        const dataGroupBy = params?.groupBy || 'month' // Default to 'month'
        const betweenDates = params?.between
        let dateDifference = 0

        if (betweenDates) {
          const [startDate, endDate] = betweenDates
          dateDifference = calculateDateDifference(startDate, endDate)
        }

        // Determine data based on date difference and groupBy value
        if (dateDifference <= 7) {
          switch (dataGroupBy) {
            case 'month':
              data = linearLast7DaysMonthData
              break
            case 'quarter':
              data = linearLast7DaysQuarterData
              break
            case 'year':
              data = linearLast7DaysYearData
              break
            default:
              data = linearLast7DaysMonthData
          }
        }
        else if (dateDifference <= 30) {
          switch (dataGroupBy) {
            case 'month':
              data = linearLast30DaysMonthData
              break
            case 'quarter':
              data = linearLast30DaysQuarterData
              break
            case 'year':
              data = linearLast30DaysYearData
              break
            default:
              data = linearLast30DaysMonthData
          }
        }
        else if (dateDifference <= 90) {
          switch (dataGroupBy) {
            case 'month':
              data = linearLast90DaysMonthData
              break
            case 'quarter':
              data = linearLast90DaysQuarterData
              break
            case 'year':
              data = linearLast90DaysYearData
              break
            default:
              data = linearLast90DaysMonthData
          }
        }
        else {
          // If no 'between' provided or it exceeds 90 days, fallback to default data by dataGroupBy
          switch (dataGroupBy) {
            case 'month':
              data = linearMonthData
              break
            case 'quarter':
              data = linearQuarterData
              break
            case 'year':
              data = linearYearData
              break
            default:
              data = linearMonthData // Default to month data
          }
        }

        return { data }
      case 'dashboard/graphs/a7416af9-6e2c-42be-9182-c1c59c76e475':
        return {
          data: {
            series: [
              {
                name: 'Tasks',
                data: [
                  {
                    name: 'Spanish (Puerto Rico)',
                    y: 1,
                  },
                  {
                    name: 'Spanish (Spain)',
                    y: 1,
                  },
                  {
                    name: 'Spanish (USA)',
                    y: 1,
                  },
                ],
              },
            ],
          },
        }
      case 'dashboard/widgets/words-total':
        const optionsWordsTotal: { [key: string]: { data: { total: number } } } = {
          this_year: { data: { total: 20 } },
          last_year: { data: { total: 524 } },
          this_quarter: { data: { total: 42 } },
          last_quarter: { data: { total: 142 } },
        }
        return optionsWordsTotal[params.relativeDate as keyof typeof optionsWordsTotal] ?? 0
      case 'dashboard/widgets/invoices-average':
        const optionsInvoiceAverage: { [key: string]: { data: { total: number } } } = {
          this_year: { data: { total: 687 } },
          last_year: { data: { total: 25325 } },
          this_quarter: { data: { total: 1543 } },
          last_quarter: { data: { total: 987 } },
        }
        return optionsInvoiceAverage[params.relativeDate as keyof typeof optionsInvoiceAverage] ?? 0
      case 'dashboard/widgets/spend-total':
        const optionsSpendTotal: { [key: string]: { data: { total: number } } } = {
          this_year: { data: { total: 762 } },
          last_year: { data: { total: 4563 } },
          this_quarter: { data: { total: 645 } },
          last_quarter: { data: { total: 854 } },
        }
        return optionsSpendTotal[params.relativeDate as keyof typeof optionsSpendTotal] ?? 0
      case 'dashboard/widgets/satisfaction-score':
        const optionsSatisfactionScore: { [key: string]: { data: number } } = {
          this_year: { data: 100 },
          last_year: { data: 75 },
          this_quarter: { data: 25 },
          last_quarter: { data: 16 },
        }
        return optionsSatisfactionScore[params.relativeDate as keyof typeof optionsSatisfactionScore] ?? 0
      case 'dashboard/widgets/projects-open':
        return {
          data: {
            total: baseProjects.value.filter(project => project.status === 'opened').length,
          },
        }
      case 'resources/offices':
        return {
          data: [
            {
              id: '1459',
              name: 'Beta Client AvantPortal',
            },
          ],
        }
      case 'dashboard/widgets/invoices-unpaid':
        return {
          data: {
            total: invoices.filter(invoice => invoice.status === 'not_paid').length,
          },
        }
      case 'dashboard/widgets/quotes-open':
        return {
          data: {
            total: baseQuotes.value.filter(quote => quote.status === 'requested').length,
          },
        }
      case 'quotes':
        if (params?.status[0] === 'SENT') {
          return {
            data: baseQuotes.value.filter(quote => quote.status === 'SENT'),
          }
        }
        if (params?.status[0] === 'ACCEPTED' || params?.status[0] === 'REJECTED' || params?.status[0] === 'ACCEPTED_BY_CUSTOMER') {
          return {
            data: baseQuotes.value.filter(quote => quote.status === 'ACCEPTED' || quote.status === 'REJECTED' || quote.status === 'ACCEPTED_BY_CUSTOMER'),
          }
        }
        return {
          data: baseQuotes.value,
        }
      case 'projects/168855':
        return {
          data: projectData1Ref.value,
        }
      case 'projects/778562':
        return {
          data: projectData2Ref.value,
        }
      case 'projects/177751':
        return {
          data: projectData3Ref.value,
        }
      case 'projects/178125':
        return {
          data: projectData4Ref.value,
        }
      case 'projects/167114':
        return {
          data: projectData5Ref.value,
        }
      case 'projects/168890':
        return {
          data: projectData6Ref.value,
        }
      case 'projects/167981':
        return {
          data: projectData7Ref.value,
        }
      case 'projects/123456':
        return {
          data: { project: baseProjects.value[baseProjects.value.length - 1] },
        }
      case 'quotes/123456':
        return {
          data: { quote: baseQuotes.value[baseQuotes.value.length - 1] },
        }
      case 'quotes/57896':
        return {
          data: quoteData1,
        }
      case 'quotes/54325':
        return {
          data: quoteData2,
        }
      case 'quotes/96325':
        return {
          data: quoteData3,
        }
      case 'quotes/74851':
        return {
          data: quoteData4,
        }
      case 'quotes/57962':
        return {
          data: quoteData5,
        }
      case 'quotes/55252':
        return {
          data: quoteData6,
        }
      case 'quotes/25259':
        return {
          data: quoteData7,
        }
      case 'dashboard/gallery':
        return {
          data: baseGallery,
        }
      case 'dashboard/graphs/ae219ddf-0000-4471-95ed-94fdfaf52233':
        return {
          data: wordsBreakdownChart,
        }
      case 'dashboard/graphs/a3336af9-6e2c-42be-1111-c1c59c76e000':
        return {
          data: spendComparisonChart,
        }
      case 'dashboard/graphs/5bd877b9-2d3c-4ebe-1111-0ecf9f524545':
        return {
          data: spendProjectsChart,
        }
      case 'templates':
        return {
          data: baseTemplates.value,
          pagination: {
            total: baseTemplates.value.length,
            page: 1,
            limit: 10,
          },
        }
      case 'templates/92':
        return {
          data: baseTemplate.value,
        }
      case 'templates/123456':
        return {
          data: baseTemplates.value[baseTemplates.value.length - 1],
        }
      case 'invoices':
        if (params?.paymentStatus[0] === 'FULLY_PAID') {
          return {
            data: invoices.filter(invoice => invoice.paymentStatus === 'FULLY_PAID'),
          }
        }
        return {
          data: invoices,
        }
      case 'resources/specializations':
        return {
          data: resourcesSpecializations,
        }
      case 'resources/settings/1459':
        return {
          data: resourcesSetting,
        }
      case 'resources/settings/schema':
        return {
          data: resourcesSettingsSchema,
        }
      case 'resources/settings':
        return {
          data: resourcesSettings,
        }
      case 'resources/services/1459':
        return {
          data: resourcesServices,
        }
      case 'resources/services?all=true':
      case 'resources/services?all=false':
        return {
          data: resourcesServices,
        }
      case 'resources/priceprofiles/1459':
        return {
          data: resourcesPriceProfiles,
        }
      case 'resources/languages/1459':
        return {
          data: resourcesLanguages,
        }
      case 'resources/languages?all=true':
      case 'resources/languages?all=false':
        return {
          data: resourcesLanguages,
        }
      case 'resources/deadlines/1459':
        return {
          data: resourcesDeadlines,
        }
      case 'resources/reject-reasons':
        return {
          data: resourcesRejectReasons,
        }
      case 'members':
        return {
          data: members,
        }
      default:
        return {
          data: [],
        }
    }
  }

  const mockPostRequest = async (endpoint: string, body: any) => {
    switch (endpoint) {
      case 'projects/v2':
        baseProjects.value = [...baseProjects.value, { ...baseProjects.value[0], ...body, id: '123456', idNumber: 'Q-MTC-20240720-1' }]
        return {
          data: {
            project: baseProjects.value[baseProjects.value.length - 1],
          },
        }
      case 'quotes':
        baseQuotes.value = [...baseQuotes.value, { ...baseQuotes.value[0], ...body, id: '123456', idNumber: 'Q-MTC-20240720-1' }]
        return {
          data: {
            quote: baseQuotes.value[baseQuotes.value.length - 1],
          },
        }
      case 'files':
        return {
          data: [{
            name: body.name,
          }],
        }
      case 'dashboard':
        const seenIds = new Set() // Keep track of processed IDs
        const updatedGalleryData = body.graphs.reduce((acc: any[], graph: any) => {
          // Find the corresponding object in galleryPostData by ID
          const matchingItem = galleryPostData.data.find(item => item.id === graph.id)

          if (matchingItem && !seenIds.has(graph.id)) {
            // Add the ID to the set and merge the object with new options
            seenIds.add(graph.id)
            acc.push({
              ...matchingItem,
              options: { ...graph.options },
            })
          }
          return acc // Accumulate the results
        }, [])

        // Update baseGallery.value to reflect the active state based on body.graphs IDs
        const bodyIds = new Set<string>(body.graphs.map((graph: { id: string }) => graph.id))
        baseGallery.value = baseGallery.value.map(item => ({
          ...item,
          active: bodyIds.has(item.id), // Set active to true if the ID is in body, otherwise false
        }))

        return {
          data: updatedGalleryData,
        }
      case 'templates':
        baseTemplates.value = [...baseTemplates.value, { ...baseTemplates.value[0], ...body, id: '123456' }]
        return {
          data: {
            quote: baseTemplates.value[baseTemplates.value.length - 1],
          },
        }
    }
  }

  const mockPutRequest = async (endpoint: string, body: any) => {
    switch (endpoint) {
      case 'templates/92':
        baseTemplate.value = { ...baseTemplate.value, ...body }
        return {
          data: baseTemplate.value,
        }
      case 'quotes/54325/approve':
      case 'quotes/96325/approve':
        const quoteId = endpoint.split('/')[1]
        baseQuotes.value = baseQuotes.value.map((quote) => {
          if (quote.id === quoteId) {
            return { ...quote, status: 'requested' }
          }
          return quote
        },
        )
        break
      case 'quotes/54325/reject':
      case 'quotes/96325/reject':
        const quoteIdReject = endpoint.split('/')[1]
        baseQuotes.value = baseQuotes.value.map((quote) => {
          if (quote.id === quoteIdReject) {
            return { ...quote, status: 'REJECTED' }
          }
          return quote
        },
        )
        break
      case 'projects/168855/instructions':
        projectData1Ref.value.project = { ...projectData1Ref.value.project, customerSpecialInstructions: body.from_customer }
        break
      case 'projects/778562/instructions':
        projectData2Ref.value.project = { ...projectData2Ref.value.project, customerSpecialInstructions: body.from_customer }
        break
      case 'projects/177751/instructions':
        projectData3Ref.value.project = { ...projectData3Ref.value.project, customerSpecialInstructions: body.from_customer }
        break
      case 'projects/178125/instructions':
        projectData4Ref.value.project = { ...projectData4Ref.value.project, customerSpecialInstructions: body.from_customer }
        break
      case 'projects/167114/instructions':
        projectData5Ref.value.project = { ...projectData5Ref.value.project, customerSpecialInstructions: body.from_customer }
        break
      case 'projects/168890/instructions':
        projectData6Ref.value.project = { ...projectData6Ref.value.project, customerSpecialInstructions: body.from_customer }
        break
      case 'projects/167981/instructions':
        projectData7Ref.value.project = { ...projectData7Ref.value.project, customerSpecialInstructions: body.from_customer }
        break
      case 'projects/123456/instructions':
        const projectId = endpoint.split('/')[1]
        baseProjects.value = baseProjects.value.map((project) => {
          if (project.id === projectId) {
            return { ...project, customerSpecialInstructions: body.from_customer }
          }
          return project
        },
        )
        console.log('baseProjects.value', baseProjects.value)
        break
    }
  }

  return {
    mockGetRequest,
    mockPostRequest,
    mockPutRequest,
    isDemoMode,
  }
})
